import React, { FC, useState } from "react";
//import Drawer from "../../drawer/Drawer";

import { Play, X } from "lucide-react";
import MediaDrawer from "../../drawer/MediaDrawer";

//import AudioPng from "../../../assets/audio/Audio.jpg";
import AudioPlayer from "../../player/AudioPlayer";
import ArticleDrawer from "../../drawer/ArticleDrawer";

const MediaCard = ({
  // categoryID,
  id,
  title,
  type,
  description,
  thumbnail,
  largeImage,
  videoURL,
  audioURL,
  video,
}: MediaCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [openArticleDrawer, setOpenArticleDrawer] = useState<boolean>(false);

  const handleCardClick = () => {
    if (type === "Video" || type === "Audio") {
      setShowModal(true);
      if (type === "Video") {
        setVideoSource(videoURL);
      }
    } else if (type === "Article") {
      setOpenArticleDrawer(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCloseArticles = () => {
    setOpenArticleDrawer(false);
  };

  return (
    <>
      <div className="MediaCard--Container bg-muted dark:bg-[#252B39] p-2 rounded-2xl">
        <div
          style={{ width: "280px" }}
          className="MediaCard  min-w-[262px] sm:min-w-[276px] max-w-[262px] md:max-w-[300px] md:min-w-[300px]   dark:border-slate-600"
        >
          <div
            className="card-img-container relative cursor-pointer hover:brightness-95"
            onClick={handleCardClick}
          >
            <img
              alt=""
              src={thumbnail ? thumbnail : largeImage}
              className="bg-slate-200 w-full h-auto aspect-[3/2] object-cover rounded-xl brightness-75"
            />
            {(videoURL || audioURL) && (
              <div className="w-7 h-7 rounded-full bg-white flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -trnaslate-y-1/2 z-10 shadow-lg">
                <Play className="text-primary w-4 h-4 ml-0.5" style={{color: "var(--primary)"}} />
              </div>
            )}
          </div>

          <div id={id} className="p-3">
            <div className="font-semibold leading-none">{title}</div>
            <div className="text-xs text-slate-500 mt-2">{description}</div>

            {/* <button>Start Now</button> */}
          </div>
        </div>
      </div>
      <MediaDrawer isOpen={showModal} onClose={closeModal}>
        <div>
          <div className="flex items-start justify-between gap-3 mb-7">
            <h3 className="text-xl text-left font-semibold leading-6 text-gray-900 dark:text-white">
              {title}
            </h3>

            <button
              className="bg-slate-500  dark:bg-slate-400 text-white dark:text-black min-w-8 w-8 h-8 aspect-square flex items-center justify-center rounded-full"
              onClick={closeModal}
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="mb-4 md:mb-0">
            {type === "Video" &&
            videoURL !== undefined &&
            videoURL !== "" &&
            !videoURL.includes("iframe") ? (
              videoURL.includes("vimeo") ? (
                <iframe
                  src={videoURL}
                  className="w-full h-auto aspect-video rounded-2xl"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={title}
                ></iframe>
              ) : (
                <video
                  src={videoSource}
                  controls
                  autoPlay
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />
              )
            ) : type === "Audio" &&
              audioURL !== undefined &&
              audioURL !== "" ? (
              <div className="flex flex-col gap-10 items-center justify-center py-4">
                {/* <img
                  src={AudioPng}
                  alt=""
                  width={70}
                  height={70}
                  className="rounded-full"
                /> */}
                <AudioPlayer
                  image={thumbnail ? thumbnail : largeImage}
                  audioUrl={audioURL}
                  audioName={title}
                  showImage={true}
                />
                {/* <audio
                  className="audio-player w-full"
                  src={audioURL}
                  controls
                  autoPlay
                /> */}
              </div>
            ) : (
              <div
                className="VideoIframe"
                dangerouslySetInnerHTML={{
                  __html: video?.embed_html || videoURL,
                }}
              />
            )}
          </div>
        </div>
      </MediaDrawer>
      <ArticleDrawer
        articleID={id}
        articleTitle={title}
        isOpen={openArticleDrawer}
        onClose={handleCloseArticles}
      />
    </>
  );
};

export default MediaCard;

import { FC, Fragment, useState, useEffect } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import {
  X,
  Mail,
  SunMoon,
  Globe,
  ChevronsUpDown,
  Check,
  LogOut,
  Search,
} from "lucide-react";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import { fetchLanguages, postLanguage } from "../../api/_request";

import { useLanguage } from "../../context/LanguageContext";

import "./LanguageSelectorAlt.css";

const LanguageSelectorAlt = () => {
  //console.log("first", i18n)
  const { state, dispatch } = useAppState();
  const cachedLanguages = sessionStorage.getItem("languagesList");
  const prevSelectedLang = sessionStorage.getItem("stellaLanguage");
  const { translate, setLanguage } = useLanguage();

  const [languageList, setLanguageList] = useState<any[]>([]);
  const [searchLanguageInput, setSearchLanguageInput] = useState<string | null>(
    null
  );
  const [filteredLanguageList, setFilteredLanguageList] = useState<any>([]);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<any>({
    addToState: false,
    selectedLanguage: null,
    languageID: null,
    languageCode: null,
  });

  useEffect(() => {
    //console.log("cachedLanguages", cachedLanguages)
    if (cachedLanguages === undefined || cachedLanguages === null) {
      getLanguages();
    } else {
      setLanguageList(JSON.parse(cachedLanguages));
      setFilteredLanguageList(JSON.parse(cachedLanguages));
      if (prevSelectedLang === undefined || prevSelectedLang === null) {
        localStorage.setItem(
          "stellaLanguage",
          JSON.stringify(JSON.parse(cachedLanguages)[0])
        );
        dispatch({
          type: ActionTypes.SET_LANGUAGE_JSON,
          payload: JSON.parse(cachedLanguages)[0],
        });

        dispatch({
          type: ActionTypes.SET_LANGUAGE,
          payload: JSON.parse(cachedLanguages)[0].name,
        });

        dispatch({
          type: ActionTypes.SET_LANGUAGE_DIRECTION,
          payload: JSON.parse(cachedLanguages)[0].direction,
        });
      } else {
        //console.log("filteredLanguageList", filteredLanguageList)
        const filteredLanguageItem = JSON.parse(cachedLanguages)?.filter(
          (item: any) => item.name === JSON.parse(prevSelectedLang)?.name
        );
        //console.log("filteredLanguageItem", filteredLanguageItem);
        setLanguage(filteredLanguageItem[0]?.lang_code);
        dispatch({
          type: ActionTypes.SET_LANGUAGE,
          payload: JSON.parse(prevSelectedLang)?.name,
        });
        dispatch({
          type: ActionTypes.SET_LANGUAGE_JSON,
          payload: JSON.parse(prevSelectedLang),
        });
        dispatch({
          type: ActionTypes.SET_LANGUAGE_DIRECTION,
          payload: filteredLanguageItem[0].direction,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (searchLanguageInput !== null) {
      const filteredLanguages = languageList.filter((item) =>
        item.name.toLowerCase().includes(searchLanguageInput.toLowerCase())
      );

      if (searchLanguageInput !== null) {
        setFilteredLanguageList(filteredLanguages);
      }
    }
  }, [searchLanguageInput]);

  useEffect(() => {
    if (updateMessage?.selectedLanguage !== null && !hasRun) {
      //INTERNATIONALIZATION
      const newMessage = {
        response: "update",
        type: "notification",
        message: translate("LanguageChanged", {
          language: translate("Language"),
        }),
        language: updateMessage.selectedLanguage,
        update: "Language",
      };

      /*const newMessage = {
        response: "update",
        type: "notification",
        message: `Enjoy your conversations with me in ${updateMessage.selectedLanguage}`,
        language: updateMessage.selectedLanguage,
        update: "Language",
      };*/

      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [...state.chatHistory, newMessage],
      });

      setUpdateMessage((prevData: any) => ({
        ...prevData,
        addToState: false,
      }));
      setHasRun(true);
    }
  }, [updateMessage?.addToState]);

  // console.log("filteredLanguageList", filteredLanguageList)

  const getLanguages = async () => {
    try {
      const res = await fetchLanguages();
      if (res) {
        //console.log("RES", res);
        // Process your response here, e.g., setting state
        const { languages } = res;
        languages.forEach((language) => {
          language.lang_code = language.lang_code.replace("-", "_");
        });
        sessionStorage.setItem("languagesList", JSON.stringify(languages));
        setLanguageList(languages);
        setFilteredLanguageList(languages);
        if (prevSelectedLang === undefined || prevSelectedLang === null) {
          sessionStorage.setItem("stellaLanguage", languages[0].name);
          dispatch({
            type: ActionTypes.SET_LANGUAGE_JSON,
            payload: languages[0],
          });
          dispatch({
            type: ActionTypes.SET_LANGUAGE,
            payload: languages[0].name,
          });
        } else {
          dispatch({
            type: ActionTypes.SET_LANGUAGE,
            payload: prevSelectedLang,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLanguageChange = (
    openState: boolean,
    selectedLanguage: string,
    languageID: string,
    languageCode: string,
    direction: string
  ) => {
    setHasRun(false);
    //console.log("languageCode Called!", languageCode);
    dispatch({
      type: ActionTypes.SET_LANGUAGE,
      payload: selectedLanguage,
    });
    dispatch({
      type: ActionTypes.SET_LANGUAGE_DIRECTION,
      payload: direction,
    });
    setUpdateMessage((prevData: any) => ({
      ...prevData,
      addToState: true,
      selectedLanguage,
      languageID,
      languageCode,
    }));
    setLanguage(languageCode);

    const cachedLanguages = sessionStorage.getItem("languagesList");

    if (cachedLanguages) {
      const filteredLanguage = JSON.parse(cachedLanguages)?.filter(
        (item: any) => item?.name === selectedLanguage
      );

      dispatch({
        type: ActionTypes.SET_LANGUAGE_JSON,
        payload: filteredLanguage[0],
      });

      sessionStorage.setItem("stellaLanguage", JSON.stringify(filteredLanguage[0]));
    }

    //setOpenLanguageDrawer(false);
    postLanguage(languageID, state.userID);
  };

  const selectedLanguageItem = languageList?.filter(
    (language) => language.name === state?.selectedLanguage
  );
  return (
    <>
      <div className="flex items-center gap-3 text-slate-600 dark:text-white h-[42px]">
        <Globe />
        {/* INTERNATIONALIZATION */}
        <p>{translate("LanguageLabel")}</p>
        {/* <p>Language</p> */}

        <span className="ml-auto text-gray-400 lowercase">
          <Listbox value={state.selectedLanguage}>
            <div className="relative mt-1 z-10">
              <Listbox.Button className="relative w-full  cursor-pointer rounded-lg bg-[#f2f3f4] dark:bg-[#3a4358] py-2 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                <span className="block truncate text-gray-500 dark:text-gray-400">
                  {selectedLanguageItem[0]?.native_name}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronsUpDown
                    className="h-5 w-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="languagelist--container antialiased absolute right-0 mt-1 max-h-60 w-[300px] overflow-auto rounded-2xl bg-white dark:bg-[#2f374a] py-2 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {filteredLanguageList.map(
                    (language: any, languageIdx: number) => (
                      <Listbox.Option
                        key={languageIdx}
                        style={{
                          backgroundColor:
                            state?.selectedLanguage === language.name
                              ? "var(--primary)"
                              : "",
                        }}
                        className={`relative select-none py-2 pl-10 pr-4 mx-2 cursor-pointer rounded-lg ${
                          state?.selectedLanguage === language.name
                            ? "bg-primary text-white"
                            : "text-gray-500 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-800"
                        }`}
                        value={language.name}
                        onClick={() =>
                          handleLanguageChange(
                            false,
                            language.name,
                            language.id,
                            language.lang_code,
                            language.direction
                          )
                        }
                      >
                        {({ selected }) => (
                          <div>
                            <span
                              className={`block truncate capitalize ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {language?.native_name} ({language.name})
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                                <Check className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </div>
                        )}
                      </Listbox.Option>
                    )
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </span>
      </div>
    </>
  );
};

export default LanguageSelectorAlt;

import React, { useState } from "react";
import SettingsDrawer from "../drawer/SettingsDrawer";
import { Settings as SettingsIcon } from "lucide-react";
import { useAppState } from "../../context/AppStateContext";
import { Skeleton } from "../skeleton/skeleton";

const SettingsOptions = () => {
  const { state } = useAppState();

  const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false);

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };

  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.25)` // Adjust the alpha value as needed
    : "";

  const handleCloseSettings = () => {
    setOpenSettingsDialog(false);
  };

  //console.log("theme", state?.appTheme);
  return (
    <div>
      {state?.userLocation === null ? (
        <Skeleton className="w-11 h-11 rounded-xl" />
      ) : (
        <button
          onClick={() => {
            setOpenSettingsDialog(!openSettingsDialog);
          }}
          style={{
            backgroundColor: rgbaColor,
            color: `${state?.appTheme === "Light" ? "var(--primary)" : "#FFF"}`,
          }}
          className="aspect-square w-11 flex text-primary bg-primary/10 rounded-xl dark:text-white"
        >
          <SettingsIcon className="m-auto" />
        </button>
      )}

      <SettingsDrawer
        isOpen={openSettingsDialog}
        onClose={handleCloseSettings}
      />
    </div>
  );
};

export default SettingsOptions;

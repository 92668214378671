//Stella Container
export const stellaContainerClass =
  "bg-white dark:bg-[#020611]  h-[100dvh] w-screen max-h-[100dvh] flex flex-col";

//ChatBody Classes
export const responseAreaClass =
  "response-area flex flex-state gap-2 max-w-6xl mx-auto";
export const avatarImageClass =
  "rounded-full aspect-square min-w-[40px] w-[40px] h-[40px] object-cover";
export const serverResponseClass =
  "server-response bg-muted dark:bg-muted-dark dark:text-white grid gap-1 p-3 rounded-xl max-w-[276px] xs:max-w-[350px] md:max-w-[500px] w-max text-sm font-medium break-words";
export const myResponseClass =
  "server-response bg-primary text-white flex flex-col gap-4 p-3 rounded-xl max-w-[276px] xs:max-w-[350px] md:max-w-[500px] w-max text-sm font-medium ml-auto break-words";
export const responseButtonsClass = "response-buttons flex flex-col gap-2 mt-2";
export const buttonItemClass =
  "button--item rounded-xl px-4 py-1.5 w-fit border border-slate-400 dark:border-slate-300 text-slate-900 dark:text-white text-sm font-medium cursor-pointer";

//ChatTextInputArea Classes
//ADD the following class when smart buttons are implemented chatTextAreaContainerClass: border-t border-solid border-slate-300 dark:border-slate-500
export const chatTextAreaContainerClass =
  "flex items-end gap-2 bg-white dark:bg-[#020611] backdrop-blur-md  py-3 px-3 sm:rounded-b-2xl";

export const smartButtonItemClass =
  "flex items-center gap-1 py-2 pl-3 pr-2 rounded-lg text-primary dark:text-white bg-primary-400/20 dark:bg-white/20";

export const chatInputClass =
  "block w-full rounded-[2rem]  py-3 pr-14 text-gray-900  placeholder:text-gray-400 text-[16px] sm:leading-6 focus-visible:outline-0 dark:bg-slate-900 dark:text-slate-100 antialiased";

export const microphoneButtonClass =
  "w-10 h-10 md:w-11 md:h-11 aspect-square flex items-center justify-center  rounded-full absolute -right-4 md:-right-5 -bottom-0.5 -translate-x-1/2 -translate-y-1 z-20";

export const stopRecordingButtonClass =
  "w-10 h-10 md:w-11 md:h-11 aspect-square flex items-center justify-center  rounded-full absolute -right-4 md:-right-5 -bottom-0.5 -translate-x-1/2 -translate-y-1 z-20";

export const sendChatButtonClass =
  "relative  inline-flex items-center rounded-full shadow-md aspect-square w-11 h-11 text-sm font-semibold ring-0 disabled:bg-slate-400 dark:disabled:bg-slate-600 bg-primary text-white";

import dayjs from "dayjs";
import { useState } from "react";
import { DocTypes, downloadFile } from "../../lib/utils";
import FullScreenBottomDrawer from "../drawer/FullScreenBottomDrawer";

const MyDocumentItem = ({
  file_type,
  category_name,
  new_file_name,
  uploaded_datetime,
  image_url,
  doctor_name,
}: any) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [docUrl, setDocUrl] = useState<null | string>(null);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setDocUrl(null);
  };

  const handleViewDoc = (url: string) => {
    if (window.ReactNativeWebView) {
      //Open In Drawer
      setDocUrl(url);
      setOpenDrawer(true);
    } else {
      window.open(url);
    }
  };
  return (
    <>
      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        {/* <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" /> */}
        <img
          className="min-w-12 w-12 h-12 object-contain rounded-md mb-auto"
          src={DocTypes[file_type as keyof typeof DocTypes] || DocTypes.png}
          alt={`${file_type} preview`}
        />
        <div>
          <span className="text-[0.6rem] font-medium uppercase tracking-wide px-2 py-1 bg-green-100/20 rounded-full text-green-600 ring-1 ring-inset ring-green-600/20">
            {category_name}
          </span>
          <h6 className="font-medium line-clamp-1 mt-1">{new_file_name}</h6>
          <p className="text-xs text-slate-500">
            {doctor_name},&nbsp;
            {dayjs.utc(uploaded_datetime).local().format("DD MMM, YYYY")}
          </p>
        </div>

        <button
          className="ml-auto px-3 py-1 text-sm font-medium border rounded-lg dark:border-white"
          onClick={() => {
            handleViewDoc(image_url);
            //downloadFile(image_url, new_file_name)
          }}
        >
          View
        </button>
      </div>

      <FullScreenBottomDrawer isOpen={openDrawer} onClose={handleCloseDrawer}>
        {docUrl && (
          <img
            src={docUrl}
            className="w-auto h-auto object-contain rounded-3xl mx-auto"
          />
        )}
      </FullScreenBottomDrawer>
    </>
  );
};

export default MyDocumentItem;

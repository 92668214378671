import React from "react";

const ArticleSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="w-full h-auto aspect-[3/2] rounded-2xl object-cover bg-slate-300 dark:bg-slate-700"></div>
      <div className="my-3">
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 max-w-20"></div>
      </div>

      <div className="grid gap-3">
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-[80%]"></div>

        <div></div>
        <div></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-[80%]"></div>

        <div></div>
        <div></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-full"></div>
        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded-lg col-span-2 w-[80%]"></div>
      </div>
    </div>
  );
};

export default ArticleSkeleton;

import React from "react";
import { Skeleton } from "./skeleton";

const MyDocumentsSkeleton = () => {
  return (
    <>
      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" />
        <div className="w-full gap-1">
          <Skeleton className="h-[20px] w-[70px] rounded-full" />
          <Skeleton className="w-[90%] h-[20px] mt-1" />
          <Skeleton className="h-[16px] w-[60%] mt-1" />
        </div>
        <Skeleton className="ml-auto h-[30px] w-[88px] rounded-lg" />
      </div>

      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" />
        <div className="w-full gap-1">
          <Skeleton className="h-[20px] w-[70px] rounded-full" />
          <Skeleton className="w-[90%] h-[20px] mt-1" />
          <Skeleton className="h-[16px] w-[60%] mt-1" />
        </div>
        <Skeleton className="ml-auto h-[30px] w-[88px] rounded-lg" />
      </div>

      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" />
        <div className="w-full gap-1">
          <Skeleton className="h-[20px] w-[70px] rounded-full" />
          <Skeleton className="w-[90%] h-[20px] mt-1" />
          <Skeleton className="h-[16px] w-[60%] mt-1" />
        </div>
        <Skeleton className="ml-auto h-[30px] w-[88px] rounded-lg" />
      </div>

      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" />
        <div className="w-full gap-1">
          <Skeleton className="h-[20px] w-[70px] rounded-full" />
          <Skeleton className="w-[90%] h-[20px] mt-1" />
          <Skeleton className="h-[16px] w-[60%] mt-1" />
        </div>
        <Skeleton className="ml-auto h-[30px] w-[88px] rounded-lg" />
      </div>

      <div className="flex items-center gap-2 px-4 py-2 text-sm">
        <Skeleton className="min-w-12 w-12 h-12 rounded-md mb-auto" />
        <div className="w-full gap-1">
          <Skeleton className="h-[20px] w-[70px] rounded-full" />
          <Skeleton className="w-[90%] h-[20px] mt-1" />
          <Skeleton className="h-[16px] w-[60%] mt-1" />
        </div>
        <Skeleton className="ml-auto h-[30px] w-[88px] rounded-lg" />
      </div>
    </>
  );
};

export default MyDocumentsSkeleton;

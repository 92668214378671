import React, { useEffect, useState } from "react";
import StateHandler from "../components/header/StateHandler";
import { MoveLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Skeleton } from "../components/skeleton/skeleton";
import { CirclePlus } from "lucide-react";
import {
  createJWT,
  decryptPayload,
  DocTypes,
  encryptPayload,
} from "../lib/utils";

import { GetMyDocuments } from "../api/_request";
import { useAuth } from "../auth";
import { useAppState } from "../context/AppStateContext";
import { FileText } from "lucide-react";

import { ActionTypes } from "../reducer/actionTypes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MyDocumentItem from "../components/documents/MyDocumentItem";
import NotLoggedInItem from "../components/documents/NotLoggedInItem";
import MyDocumentsSkeleton from "../components/skeleton/MyDocumentsSkeleton";
import DocumentSearch from "../components/documents/DocumentSearch";

import useDebounce from "../lib/useDebounce";

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const MyDocumentsScreen = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { state, dispatch } = useAppState();

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [selectedTag, setSelectedTag] = useState<null | string>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const debouncedSearchInput = useDebounce(searchInput, 700); // Debounce for 500ms

  useEffect(() => {
    //handleEncryption();

    if (state?.authToken && currentUser) FetchMyDocuments(1);
  }, [currentUser, state?.authToken]);

  useEffect(() => {
    //console.log("selectedTag", selectedTag)
    //console.log(first)

    if (
      (state?.authToken && currentUser) ||
      debouncedSearchInput.trim() !== ""
    ) {
      setPage(1);
      FetchMyDocuments(1);
    }
  }, [selectedTag, debouncedSearchInput]);

  //SAMPLE ENCRYTION CODE
  const handleEncryption = async () => {
    const payload = {
      userId: "12345",
      name: "John Doe",
    };

    const encryptionKey = "b1d56a8d3f87c3e5e8c5e7a2a8d2b1c4";
    const jwtSecret = "4f7e3e73d2f1b3e7c8d3a9d2e5b1a7c2";

    try {
      const encryptedPayload = await encryptPayload(payload, encryptionKey);
      const token = await createJWT(encryptedPayload, jwtSecret);

      console.log("encryptedPayload", encryptedPayload);
      console.log("token", token);

      handleDecryption(encryptedPayload);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDecryption = async (encryptedPayload: any) => {
    const encryptionKey = "b1d56a8d3f87c3e5e8c5e7a2a8d2b1c4"; // Use the same key used for encryption

    try {
      const data = await decryptPayload(encryptedPayload, encryptionKey);
      console.log("decrypted Data: ", data);
    } catch (error) {
      console.error("Decryption failed:", error);
    }
  };

  const FetchMyDocuments = async (pageNumber?: number) => {
    try {
      const myDocsQueries = {
        userID: currentUser?.UserId + "",
        count: 10,
        page: pageNumber,
        token: state?.authToken,
        category: selectedTag,
        search: searchInput,
      };
      const documentsResponse = await GetMyDocuments(myDocsQueries);

      if (documentsResponse?.status == 200) {
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS,
          payload: documentsResponse?.data?.data,
        });
        //setMyDocuments(documentsResponse?.data?.data);
        setTotalPages(documentsResponse?.data?.total_pages);
        setTotalCount(documentsResponse?.data?.total_count);
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS_COUNT,
          payload: documentsResponse?.data?.total_count,
        });
      }
    } catch (error) {}
  };

  const handleValue = (data: any) => {
    // console.log("DATA", data);
    setSelectedTag(data);
  };

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  //console.log("currentUser", currentUser);

  return (
    <div className="h-[100dvh] overflow-y-auto dark:bg-[#010a24]">
      <div className=" bg-white/40 dark:bg-[#010a24aa] px-4 py-5 border-b sticky top-0 z-10 backdrop-blur-md">
        <div className="max-w-4xl w-full mx-auto flex items-center justify-between gap-3">
          <div className="ml-3 flex items-center">
            <button
              type="button"
              onClick={() => navigate("/")}
              className="relative rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <ArrowLeft
                aria-hidden="true"
                className="h-5 w-5 text-slate-500 dark:text-slate-300"
              />
            </button>
          </div>
          <div className="text-xl flex-1 font-medium leading-6 text-gray-900 dark:text-white">
            My Documents
          </div>
        </div>
      </div>
      {/* CONTENT */}
      <div className="max-w-4xl w-full mx-auto mt-12 px-5 space-y-4 pb-[80px]">
        {state?.isUserLoggedIn ? (
          <>
            <DocumentSearch
              selectedTag={selectedTag}
              handleValue={handleValue}
              handleInputChange={handleInputChange}
              searchInput={searchInput}
            />

            {/* TABLE */}
            <div className="divide-y dark:divide-gray-500 border dark:border-gray-500 py-1 rounded-xl">
              {state?.myDocuments ? (
                state?.myDocuments?.length > 0 ? (
                  state?.myDocuments?.map((item: any, i: number) => (
                    <MyDocumentItem key={i} {...item} />
                  ))
                ) : (
                  <div className="flex items-center gap-1 px-4 py-2 text-sm text-center text-gray-700 dark:text-gray-200">
                    <FileText className="w-4 h-4" />
                    <h5 className="font-medium tracking-tight">
                      No Documents!
                    </h5>
                  </div>
                )
              ) : (
                <MyDocumentsSkeleton />
              )}
            </div>
            {state?.myDocuments && totalPages > 0 ? (
              <div className="flex items-center gap-3 ml-auto w-max">
                <span className="text-xs font-medium text-gray-400 dark:text-gray-200">
                  Page {page} of {totalPages}
                </span>

                <button
                  disabled={page == 1}
                  onClick={() => {
                    setPage(page - 1);
                    FetchMyDocuments(page - 1);
                  }}
                  className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:border-white dark:disabled:text-gray-300 dark:disabled:bg-slate-600 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs text-slate-600"
                >
                  Previous
                </button>
                <button
                  disabled={totalPages <= page}
                  onClick={() => {
                    setPage(page + 1);
                    FetchMyDocuments(page + 1);
                  }}
                  className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:border-white dark:disabled:text-gray-300 dark:disabled:bg-slate-600 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs text-slate-600"
                >
                  Next
                </button>
              </div>
            ) : null}
          </>
        ) : (
          <NotLoggedInItem />
        )}
      </div>
    </div>
  );
};

export default MyDocumentsScreen;

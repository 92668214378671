import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(HttpBackend) // Load translations over HTTP
  .use(LanguageDetector) // Detects user language
  .init({
    fallbackLng: "en", // Use English if the language can't be determined
    lng: "en", // Default language
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;

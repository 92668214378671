import { useState, useEffect, useReducer, useRef } from "react";

import "./App.css";
import Header from "./components/header/Header";
import { useAppState } from "./context/AppStateContext";
import { ActionTypes } from "./reducer/actionTypes";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ChatBody from "./components/chatBody/ChatBody";
import ChatTextInputArea from "./components/chatTextInputArea/ChatTextInputArea";
import { validateToken } from "./api/_request";
import { stellaContainerClass } from "./helpers/helperClasses";
import StellaScreen from "./pages/StellaScreen";
import MyDocumentsScreen from "./pages/MyDocumentsScreen";
import StateHandler from "./components/header/StateHandler";

const App: React.FC = () => {
  const { state, dispatch } = useAppState();

  useEffect(() => {
    const htmlEl = document.getElementsByTagName("html")[0];
    if (localStorage.getItem("stellaTheme")?.toLocaleLowerCase() === "system") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        // dark mode
        //console.log("DARK");

        if (htmlEl.classList.contains("light")) {
          htmlEl.classList.remove("light");
        } else if (!htmlEl.classList.contains("dark")) {
          htmlEl.classList.add("dark");
        }
      } else {
        if (htmlEl.classList.contains("dark")) {
          htmlEl.classList.remove("dark");
        } else if (!htmlEl.classList.contains("light")) {
          htmlEl.classList.add("light");
        }
      }
    } else if (
      localStorage.getItem("stellaTheme")?.toLocaleLowerCase() === "dark"
    ) {
      htmlEl.classList.remove("light");
      htmlEl.classList.add("dark");
    } else if (
      localStorage.getItem("stellaTheme")?.toLocaleLowerCase() === "light"
    ) {
      htmlEl.classList.remove("dark");
      htmlEl.classList.add("light");
    } else {
      //Remove this else when settings drawer is implemented
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        // dark mode
        //console.log("DARK");

        if (htmlEl.classList.contains("light")) {
          htmlEl.classList.remove("light");
        } else if (!htmlEl.classList.contains("dark")) {
          htmlEl.classList.add("dark");
        }
      } else {
        if (htmlEl.classList.contains("dark")) {
          htmlEl.classList.remove("dark");
        } else if (!htmlEl.classList.contains("light")) {
          htmlEl.classList.add("light");
        }
      }
    }
  }, []);

  return (
    <main className="relative bg-slate-200/40 dark:bg-[#010a24] h-[100dvh] w-screen overflow-hidden">
      <Router>
        <div className={stellaContainerClass}>
          <StateHandler />
          <Routes>
            <Route path="/" element={<StellaScreen />} />
            <Route path="/documents" element={<MyDocumentsScreen />} />
          </Routes>
        </div>
      </Router>
    </main>
  );
};

export default App;

import React from "react";
import { smartButtonItemClass } from "../../helpers/helperClasses";
import { cn } from "../../lib/utils";

const ButtonSuggestionItem = ({ text }: any) => {
  return (
    <button
      className={cn(
        smartButtonItemClass,
        "aspect-auto h-11 px-6 flex-nowrap min-w-max text-sm font-medium text-primary-600 bg-primary-100/60 border border-primary-200/60 rounded-full"
      )}
    >
      {text}
    </button>
  );
};

export default ButtonSuggestionItem;

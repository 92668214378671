import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react";

// Import language data, assumed to be correctly typed according to TranslationKeys
import ar from "../locales/ar.json";
import bn from "../locales/bn.json";
import de from "../locales/de.json";
import en from "../locales/en.json";
import es from "../locales/es.json";
import fr from "../locales/fr.json";
import gu from "../locales/gu.json";
import hi from "../locales/hi.json";
import id from "../locales/id.json";
import ja from "../locales/ja.json";
import kn from "../locales/kn.json";
import ko from "../locales/ko.json";
import ml from "../locales/ml.json";
import mr from "../locales/mr.json";
import pa from "../locales/pa.json";
import pt from "../locales/pt.json";
import ru from "../locales/ru.json";
import ta from "../locales/ta.json";
import te from "../locales/te.json";
import tr from "../locales/tr.json";
import ur from "../locales/ur.json";
import zh_CN from "../locales/zh-CN.json";
import zh_TW from "../locales/zh-TW.json";

import as from "../locales/as.json";
import fa from "../locales/fa.json";
import it from "../locales/it.json";
import or from "../locales/or.json";
import pl from "../locales/pl.json";
import vi from "../locales/vi.json";

export interface TranslationKeys {
  Stella: string;
  AskSam: string;
  Language: string;
  MessagePlaceholder: string;
  STTPlaceholder: string;
  TranscribingYourSpeech: string;
  Settings: string;
  AvatarChangeMessage: string;
  LanguageChanged: string;
  ThemeLabel: string;
  System: string;
  Light: string;
  Dark: string;
  EmailLabel: string;
  LanguageLabel: string;
  LoginOrRegister: string;
  Login: string;
  NotLoggedInMessage1: string;
  NotLoggedInMessage2: string;
  NotLoggedInMessage3: string;
  Beta: string;
  VoiceTranslation: string;
  Disabled: string;
  Enabled: string;
  MyDocuments: string;
  ExceedTitle: string;
  ExceedContent: string;
}

type Languages = {
  [key: string]: TranslationKeys;
};

const languages: Languages = {
  ar,
  bn,
  de,
  en,
  es,
  fr,
  gu,
  hi,
  id,
  ja,
  kn,
  ko,
  ml,
  mr,
  pa,
  pt,
  ru,
  ta,
  te,
  tr,
  ur,
  zh_CN,
  zh_TW,
  as,
  fa,
  it,
  or,
  pl,
  vi,
}; // Add other languages...

type LanguageContextType = {
  currentLang: string;
  translate: (
    key: keyof TranslationKeys,
    replacements?: Record<string, string>
  ) => string;
  setLanguage: (lang: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentLang, setCurrentLang] = useState("en");

  const translate = (
    key: keyof TranslationKeys,
    replacements?: Record<string, string>
  ): string => {
    const translationEntries = languages[currentLang];
    let translation = translationEntries[key] || key; // This line ensures that the key is valid
    if (replacements) {
      Object.keys(replacements).forEach((placeholder) => {
        const replacement = replacements[placeholder];
        translation = translation.replace(
          new RegExp(`{${placeholder}}`, "g"),
          replacement
        );
      });
    }
    return translation;
  };

  const setLanguage = (lang: string) => {
    /*INTERNATIONALIZATION*/
    setCurrentLang(lang);
  };

  const value = useMemo(
    () => ({ currentLang, translate, setLanguage }),
    [currentLang]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

import "dayjs/locale/en";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { FC, Fragment, useEffect, useState } from "react";
import { Listbox, Dialog, Transition, DialogPanel, DialogTitle } from "@headlessui/react";
import { MoveLeft } from "lucide-react";
import { useAuth } from "../../auth";
import ColorSchemeSelector from "../header/ColorSchemeSelector";
import LanguageSelectorAlt from "../header/LanguageSelectorAlt";
import { useLanguage } from "../../context/LanguageContext";
import { getArticleDetails } from "../../api/_request";
import ArticleSkeleton from "../skeleton/ArticleSkeleton";
import { ArrowLeft } from "lucide-react";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const ArticleDrawer = ({
  articleID,
  articleTitle,
  isOpen,
  onClose,
}: ArticleDrawerProps) => {
  const { currentUser } = useAuth();
  const { translate, setLanguage } = useLanguage();

  const [articlesDetails, setArticleDetails] = useState<any>(null);

  useEffect(() => {
    if (isOpen) {
      viewArticles();
    }
  }, [isOpen]);

  const viewArticles = async () => {
    try {
      const payload = {
        userId: currentUser?.UserId,
        postId: articleID,
      };
      const articleDetailsRes = await getArticleDetails(
        JSON.stringify(payload)
      );

      //console.log("articleDetailsRes", articleDetailsRes);
      setArticleDetails(articleDetailsRes);
    } catch (error) {
      console.error("Error fetching articles!", error);
    }
  };

  const convertTimestamp = (timestamp: any) => {
    // Parse the timestamp using Day.js
    const date = dayjs(timestamp);

    // Format the date as "DD/MM/YYYY HH:mm:ss"
    return date.format("Do MMM, YYYY");
  };
  //console.log("currentUser", currentUser)

  return (
    <Dialog as="div" className="relative z-50" open={isOpen} onClose={onClose}>
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="fixed inset-0 bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen transform transition duration-500 ease-in-out data-[closed]:translate-y-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-auto bg-slate-50 dark:bg-[#010a24]  pb-6 shadow-xl">
                <div className="px-4 py-2 sm:px-6 bg-slate-50/80 dark:bg-[#010a24d6]  backdrop-blur-sm sticky top-0 z-50 shadow-sm border-b">
                  <div className="flex items-start justify-between max-w-4xl w-full mx-auto sm:px-6">
                    <button
                      type="button"
                      className="flex items-center gap-3 lg:gap-4 py-4 px-3 text-primary dark:text-blue-100 focus:outline-none rounded-xl"
                      onClick={onClose}
                    >
                      <ArrowLeft className="w-6 h-6 m-auto" aria-hidden="true" />

                      <p className="font-semibold">Back</p>
                    </button>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6 max-w-4xl w-full mx-auto space-y-5">
                  <DialogTitle className="text-3xl lg:text-5xl font-bold leading-8 lg:leading-normal text-black dark:text-white w-full text-left break-words">
                    {articleTitle}
                  </DialogTitle>

                  <div className="grid gap-3 max-w-5xl w-full pb-[100px]">
                    {articlesDetails !== null ? (
                      <div>
                        <img
                          src={
                            articlesDetails?.coverImageUrl ||
                            articlesDetails?.defaultImageUrl
                          }
                          alt=""
                          className="w-full h-auto aspect-[3/2] rounded-2xl object-cover "
                        />

                        <div className="my-5 lg:my-10 dark:text-slate-200 text-sm lg:text-base font-medium text-slate-600 flex items-center gap-2">
                          <p>
                            {articlesDetails?.createDate &&
                              convertTimestamp(articlesDetails?.createDate)}
                          </p>
                          <p>.</p>
                          <p>{articlesDetails?.duration} read</p>
                        </div>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: articlesDetails?.content,
                          }}
                          className="grid gap-3 lg:text-lg text-slate-950 dark:text-slate-100"
                          style={{ wordBreak: "break-word" }}
                        />
                      </div>
                    ) : (
                      <ArticleSkeleton />
                    )}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ArticleDrawer;

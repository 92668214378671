import React from "react";
import ChatBody from "../components/chatBody/ChatBody";
import ChatTextInputArea from "../components/chatTextInputArea/ChatTextInputArea";
import Header from "../components/header/Header";
import StateHandler from "../components/header/StateHandler";

const StellaScreen = () => {
  return (
    <>
      <Header />
      <ChatBody />
      <ChatTextInputArea />
    </>
  );
};

export default StellaScreen;

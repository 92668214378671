import { FC, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { MoveLeft, X } from "lucide-react";
import { ArrowLeft } from "lucide-react";

const FullScreenBottomDrawer = ({
  isOpen,
  onClose,
  prescriptionData,
  children,
  title
}: any) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="fixed inset-0 bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen transform transition duration-500 ease-in-out data-[closed]:translate-y-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden bg-slate-50 dark:bg-[#010a24]  pb-6 shadow-xl">
               
                  <div className=" bg-slate-50 dark:bg-[#010a24] p-4 border-b sticky top-0">
                    <div className="max-w-4xl w-full mx-auto flex items-center justify-between gap-3">
                      <div className="ml-3 flex items-center">
                        <button
                          type="button"
                          onClick={onClose}
                          className="flex items-center gap-3 lg:gap-4 py-4 px-3 text-primary dark:text-blue-100 focus:outline-none rounded-xl"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <ArrowLeft aria-hidden="true" className="h-6 w-6" />
                          <p className="font-semibold">Back</p>
                        </button>
                      </div>
                      <DialogTitle className="text-base flex-1 font-semibold leading-6 text-gray-900">
                       {title}
                      </DialogTitle>
                    </div>
                  </div>
              
                {/* CONTENT START*/}
                <div className="grid gap-3 max-w-3xl mx-auto w-full p-6 pb-[100px]">
                 {children}
                </div>
                {/* CONTENT END*/}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FullScreenBottomDrawer;

import "dayjs/locale/en";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { FC, Fragment, useEffect, useState } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import { MoveLeft } from "lucide-react";
import { useAuth } from "../../auth";
import ColorSchemeSelector from "../header/ColorSchemeSelector";
import LanguageSelectorAlt from "../header/LanguageSelectorAlt";
import { useLanguage } from "../../context/LanguageContext";
import { getArticleDetails } from "../../api/_request";
import ArticleSkeleton from "../skeleton/ArticleSkeleton";
import { ArrowLeft } from "lucide-react";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const IframeDrawer = ({
  isOpen,
  onClose,
  // onData,
  children,
}: MediaDrawerProps) => {
 
  return (
    <Transition.Root appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="fixed inset-0 bg-gray-500 dark:bg-black/10 backdrop-blur-md bg-opacity-75 transition-opacity">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="ease-in-out duration-500"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen">
                  <div className="flex h-full flex-col overflow-y-hidden bg-slate-50 dark:bg-[#010a24]  pb-6 shadow-xl">
                    <div className="px-4 py-2 sm:px-6 bg-slate-50/80 dark:bg-[#010a24d6]  backdrop-blur-sm sticky top-0 z-50 shadow-sm border-b">
                      <div className="flex items-start justify-between max-w-4xl w-full mx-auto sm:px-6">
                        <button
                          type="button"
                          className="flex items-center gap-3 lg:gap-4 py-4 px-3 text-primary dark:text-blue-100 focus:outline-none rounded-xl"
                          onClick={onClose}
                        >
                          <ArrowLeft
                            className="w-6 h-6 m-auto"
                            aria-hidden="true"
                          />

                          <p className="font-semibold">Back</p>
                        </button>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 max-w-4xl w-full mx-auto space-y-5">
                      <Dialog.Title className="text-3xl lg:text-5xl font-bold leading-8 lg:leading-normal text-black dark:text-white w-full text-left break-words"></Dialog.Title>

                      <div className="grid gap-3 max-w-5xl w-full pb-[100px]">
                        {children}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default IframeDrawer;

import { useState, useEffect, useCallback } from "react";
import { UserDetailsService } from "../api/_request";
import Cookies from "js-cookie";

export const useAuth = () => {
  const [isLoggedInCheckCalled, setIsLoggedInCheckCalled] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState(() =>
    localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile")!)
      : null
  );

  const isLoggedIn = useCallback(async () => {
    const Authorization = localStorage.getItem("Authorization");
    if (Authorization) {
      try {
        const UserDetails = await UserDetailsService().then(
          (resp) => resp.data
        );
        //console.log("UserDetails", UserDetails);
        return UserDetails?.ResponseCode === 200 ?? false;
      } catch (error) {
        console.error("Error verifying login status:", error);
        return false;
      }
    }
    return false;
  }, []);

  // useEffect(() => {
  //   // Example usage: Check login status on mount
  //   const checkLoginStatus = async () => {
  //     const loggedIn = await isLoggedIn();
  //     setIsLoggedInCheckCalled(true);

  //     if (!loggedIn) {
  //       // Redirect to accounts.unitedwecare.com  which is under development
  //     }

  //   };

  //   if (!isLoggedInCheckCalled) {
  //     checkLoginStatus();
  //   }
  // }, [isLoggedIn, isLoggedInCheckCalled]);

  const login = useCallback((data: any) => {
    localStorage.setItem(
      "Authorization",
      data.token_type + " " + data.access_token
    );
    setCurrentUser(data.userdetails); // Assuming userdetails contains the profile information
    localStorage.setItem("profile", JSON.stringify(data.userdetails));

    const now = new Date();
    const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
    const firstName = data?.userdetails?.Name.split(" ").slice(0, 1).join("");
    document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;

    // Assuming you have a way to handle analytics
    // window.dataLayer.push({
    //   event: 'login',
    //   user_id: data?.userdetails?.UserId,
    // });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("profile");
    setCurrentUser(null);
    // Resetting cookies, consider abstracting cookie management
    const now = new Date();
    document.cookie = `userType=;expires=${now.toUTCString()};domain=.unitedwecare.com;path=/;`;
    document.cookie = `displayName=;expires=${now.toUTCString()};domain=.unitedwecare.com;path=/;`;
    Cookies.remove("authToken");
    window.location.reload()
  }, []);

  const updateMobileNo = useCallback(
    (mobileNumber: string) => {
      if (currentUser) {
        const updatedProfile = { ...currentUser, MobileNo: mobileNumber };
        setCurrentUser(updatedProfile);
        localStorage.setItem("profile", JSON.stringify(updatedProfile));
      }
    },
    [currentUser]
  );

  return {
    currentUser,
    isLoggedIn,
    userType: {
      EXPERT: 3,
      ENDUSER: 4,
    },
    setCurrentUser: useCallback((profile: any) => {
      setCurrentUser(profile);
      localStorage.setItem("profile", JSON.stringify(profile));
    }, []),
    login,
    logout,
    updateMobileNo,
  };
};

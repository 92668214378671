import React, { FC, ReactNode, useEffect, useState } from "react";

const DelayedRender = ({ delay, children, onComplete }: DelayedRenderProps) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (!isRendered) {
      let timeoutId = setTimeout(() => {
        setIsRendered(true);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [delay, isRendered]);

  useEffect(() => {
    if (isRendered) {
      onComplete(true);
    } else {
      onComplete(false);
    }
  }, [isRendered, onComplete]);

  return isRendered ? <>{children}</> : null;
};

export default DelayedRender;

import React, { useEffect, useState } from "react";
import { Languages, ChevronDown } from "lucide-react";
import Drawer from "../drawer/Drawer";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import LanguageSelector from "./LanguageSelector";

import { useNavigate, useLocation } from "react-router-dom";

//Icons
import {
  Plus,
  NotebookPen,
  Volume2,
  VolumeX,
  TextCursorInput,
} from "lucide-react";

import {
  callAwaitStart,
  fetchLanguages,
  GetLocationDetails,
  postLanguage,
  validateToken,
} from "../../api/_request";
import { useAuth } from "../../auth";

import Cookies from "js-cookie";
import SettingsOptions from "./SettingsOptions";
import { useLanguage } from "../../context/LanguageContext";
import {
  countriesJSON,
  hexToHSL,
  isEmpty,
  samFacesStaticList,
  stellaFacesStaticList,
} from "../../lib/utils";
import { use } from "i18next";
import { Helmet } from "react-helmet";
import { Skeleton } from "../skeleton/skeleton";

const Header: React.FC = () => {
  const { state, dispatch } = useAppState();
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const { translate, setLanguage } = useLanguage();
  const location = useLocation();
  const search = location.search;

  const searchBarParams = new URLSearchParams(search);
  const faceID = searchBarParams.get("face-id");
  const token_url = searchBarParams.get("token");
  const themeValue = searchBarParams.get("theme");

  const prevSelectedAvatar = sessionStorage.getItem("stellaAvatar");

  const [openFacesDrawer, setOpenFacesDrawer] = useState<boolean>(false);

  const [facesList, setFacesList] = useState<null | any[]>(null);

  //UseEffect for stuff that needs to run only once
  useEffect(() => {
    setFacesListCountryBased();
    setStellaFace();
  }, [state?.userLocation, currentUser?.Country]);

  useEffect(() => {
    ///Experimental
    redirectionViaIp();
  }, [state?.userLocation, currentUser]);

  //Get User location via IP
  useEffect(() => {
    //If user Country is defined then get from that
    if (!state?.userLocation) {
      getUserLocationViaIP();
    } else {
      const countryCode = countriesJSON.filter(
        (item: any) =>
          item.name === state?.userLocation || item.iso2 === state?.userLocation
      );
      //console.log("countryCode", countryCode);
      if (countryCode.length > 0) {
        dispatch({
          type: ActionTypes.SET_USER_LOCATION,
          payload: countryCode[0]?.iso2,
        });
      } else {
        dispatch({
          type: ActionTypes.SET_USER_LOCATION,
          payload: "IN",
        });
      }
    }
  }, []);

 // console.log("state", state);

  const redirectionViaIp = () => {
    if (
      state?.userLocation === "AU" ||
      currentUser?.Country?.toLocaleLowerCase() === "australia" ||
      currentUser?.Country?.toLocaleLowerCase() === "au"
    ) {
      if (!window.location.host.includes("localhost")) {
        if (window.location.host !== "asksam.unitedwecare.com") {
          const urlParams = window.location.href.split("?")[1];
          if (urlParams) {
            window.location.href = ` https://asksam.unitedwecare.com/?${urlParams}`;
          } else {
            window.location.href = ` https://asksam.unitedwecare.com/`;
          }
        }
      }

      const { hue, saturation, lightness } = hexToHSL("#1b409b");
      document.documentElement.style.setProperty("--primary", "#1b409b");
    } else {
      const { hue, saturation, lightness } = hexToHSL("#73589B");
      document.documentElement.style.setProperty("--primary", "#73589B");
    }
  };

  const getUserLocationViaIP = async () => {
    try {
      const locationData = await GetLocationDetails();
      if (+locationData?.data?.code === 200) {
        const countryCode = locationData?.data?.data?.country;

        // console.log("countryCode", countryCode)
        dispatch({
          type: ActionTypes.SET_USER_LOCATION,
          payload: countryCode,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_USER_LOCATION,
        payload: "IN",
      });
    }
  };

  const setFacesListCountryBased = () => {
    if (
      state?.userLocation !== "AU" &&
      currentUser?.Country?.toLocaleLowerCase() !== "australia" &&
      currentUser?.Country?.toLocaleLowerCase() !== "au"
    ) {
      setFacesList(stellaFacesStaticList);
    } else {
      setFacesList(samFacesStaticList);
    }
  };

  const handleUpdateFaceInContext = (selectedFace: string) => {
    //Save in session storage
    sessionStorage.setItem("stellaAvatar", selectedFace);

    //Save in central state
    dispatch({
      type: ActionTypes.SET_STELLA_AVATAR,
      payload: selectedFace,
    });
  };

  const handleSelectStellaFace = (
    selectedFace: string,
    initialLoad: boolean
  ) => {
    if (selectedFace !== state.selectedFace) {
      handleUpdateFaceInContext(selectedFace);

      /*INTERNATIONALIZATION*/
      const newMessage = {
        response: "update",
        type: "notification",
        message: translate("AvatarChangeMessage"),
        update: "Avatar",
      };

      /*const newMessage = {
        response: "update",
        type: "notification",
        message: 'Avatar has been changed. Hope you like it!',
        update: "Avatar",
      };*/

      dispatch({
        type: ActionTypes.UPDATE_CHAT_HISTORY,
        payload: [...state.chatHistory, newMessage],
      });

      //Close the face selection Drawer
      handleCloseFacesDrawer();
    }
  };

  const setStellaFace = () => {
    const HOST = window.location.host;
    if (faceID !== null && faceID !== undefined) {
      let filteredFace: StellaFaceItem;

      if (
        state?.userLocation === "AU" ||
        currentUser?.Country?.toLocaleLowerCase() === "australia" ||
        currentUser?.Country?.toLocaleLowerCase() === "au"
      ) {
        filteredFace = samFacesStaticList.filter(
          (item) => item.id === +faceID
        )[0];
      } else {
        filteredFace = stellaFacesStaticList.filter(
          (item) => item.id === +faceID
        )[0];
      }

      if (filteredFace !== undefined) {
        handleUpdateFaceInContext(filteredFace.face);
      }
    } else {
      let selectedFace;
      if (
        state?.userLocation !== "AU" &&
        currentUser?.Country?.toLocaleLowerCase() !== "australia" &&
        currentUser?.Country?.toLocaleLowerCase() !== "au"
      ) {
        selectedFace = stellaFacesStaticList[0].face;
      } else {
        selectedFace = samFacesStaticList[0].face;
      }

      handleUpdateFaceInContext(selectedFace);
    }
  };

  const handleOpenFacesDrawer = () => setOpenFacesDrawer(true);
  const handleCloseFacesDrawer = () => setOpenFacesDrawer(false);

  const toggleShowInputField = () => {
    dispatch({
      type: ActionTypes.SET_SHOW_INPUT,
      payload: !state.showInput,
    });
  };

  const toggleAudioMode = () => {
    dispatch({
      type: ActionTypes.TOGGLE_AUDIO_MODE,
    });
  };

  return (
    <div className="grid z-30 bg-white dark:bg-[#020611] fixed w-full top-0 left-0 md:rounded-t-2xl">
      <Helmet>
        {window.location.host === "asksam.unitedwecare.com" ? (
          <title>AskSam</title>
        ) : (
          <title>Stella</title>
        )}
      </Helmet>

      <div className="bg-white/60 dark:bg-[#020611]  border-b border-solid border-slate-300/70 dark:border-slate-500 px-3 py-4 md:py-2 md:px-4 flex items-center justify-between gap-5 md:rounded-t-2xl">
        {state?.userLocation === null ? (
          <Skeleton className="w-[55px] h-[55px] rounded-full" />
        ) : (
          <div className="relative" onClick={handleOpenFacesDrawer}>
            <img
              src={state?.selectedFace}
              alt=""
              className="w-[55px] h-[55px] rounded-full object-cover border-2 border-solid border-white dark:border-slate-600 cursor-pointer"
            />
            <Plus
              className="bg-primary dark:bg-primary-dark text-white p-1 w-5 h-5 rounded-full absolute bottom-0 -right-1 border border-solid border-white dark:border-slate-700"
              style={{ backgroundColor: "var(--primary)" }}
            />
          </div>
        )}

        {/* <span className="absolute top-4 left-0  flex h-3 w-3 my-auto ml-3">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
        </span> */}

        <h3 className="text-md text-lg text-slate-800 dark:text-white flex-1 font-semibold">
          {/* INTERNATIONALIZATION */}

          {state?.userLocation !== null ? (
            state?.userLocation !== "AU" &&
            currentUser?.Country?.toLocaleLowerCase() !== "australia" &&
            currentUser?.Country?.toLocaleLowerCase() !== "au" ? (
              translate("Stella")
            ) : (
              translate("AskSam")
            )
          ) : (
            <Skeleton className="w-12 h-6" />
          )}

          {/* Stella */}
        </h3>

        <div className="flex items-center gap-2">
          {/* Audio States */}
          {/* {state?.isAudioMode ? (
            <button
              className="aspect-square w-11 flex bg-primary dark:bg-primary-dark rounded-xl text-white"
              onClick={toggleAudioMode}
            >
              <Volume2 className="m-auto" />
            </button>
          ) : (
            <button
              className="aspect-square w-11 flex bg-primary/15 dark:bg-[#6d28d932] rounded-xl text-primary dark:text-white"
              onClick={toggleAudioMode}
            >
              <VolumeX className="m-auto" />
            </button>
          )} */}

          {/* Input Area Hide/Show Toggle Button */}
          {/* <button
            className={`aspect-square w-11 flex ${
              state.showInput
                ? "bg-primary dark:bg-primary-dark text-white"
                : "bg-primary/15 dark:bg-[#6d28d932] text-primary dark:text-white"
            }  rounded-xl `}
            onClick={toggleShowInputField}
          >
            <TextCursorInput className="m-auto" />
          </button> */}

          {/* <LanguageSelector /> */}
          <SettingsOptions />
        </div>

        <Drawer isOpen={openFacesDrawer} onClose={handleCloseFacesDrawer}>
          <div>
            <h3 className="text-xl font-semibold leading-6 text-gray-900 dark:text-white mb-10">
              Choose Avatar
            </h3>
            <div className="grid grid-cols-4 md:grid-cols-5 gap-3 pb-4">
              {facesList?.map((item, id) => {
                return (
                  <button
                    key={id}
                    className={`rounded-full ${
                      state.selectedFace === item.face
                        ? "ring-offset-2 dark:ring-offset-0 ring-2 ring-primary dark:ring-primary-dark"
                        : ""
                    }`}
                    onClick={() => handleSelectStellaFace(item.face, false)}
                  >
                    <img
                      className="w-full h-full aspect-square rounded-full object-cover bg-slate-200"
                      src={item.face}
                      alt=""
                      loading="lazy"
                    />
                  </button>
                );
              })}
            </div>
          </div>
          <div className="mt-3"></div>
        </Drawer>
      </div>

      {/* <div className="px-3 py-3 bg-white border-b border-solid border-slate-300 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
          </span>
          <p className="text-base font-semibold text-primary">LIVE Chat</p>
        </div>

        <div className="flex items-center gap-2">
          {state?.isAudioMode ? (
            <button
              className="aspect-square w-12 flex bg-primary  rounded-xl text-white"
              onClick={toggleAudioMode}
            >
              <Volume2 className="m-auto" />
            </button>
          ) : (
            <button
              className="aspect-square w-12 flex bg-primary/15 rounded-xl text-primary"
              onClick={toggleAudioMode}
            >
              <VolumeX className="m-auto" />
            </button>
          )}

          <button
            className={`aspect-square w-12 flex ${
              state.showInput
                ? "bg-primary text-white"
                : "bg-primary/15 text-primary"
            }  rounded-xl `}
            onClick={toggleShowInputField}
          >
            <TextCursorInput className="m-auto" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Header;

import React, { useState, useEffect, useRef } from "react";
import { Play, Pause, Volume2, VolumeX } from "lucide-react";

interface AudioPlayerProps {
  image: string;
  audioUrl: string;
  audioName?: string;
  showImage: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  image,
  audioUrl,
  audioName,
  showImage,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<string>("0:00");
  const [currentTime, setCurrentTime] = useState<string>("0:00");
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateProgress = () => {
      const currentProgress = (audio.currentTime / audio.duration) * 100;
      setProgress(currentProgress);
      setCurrentTime(formatTime(audio.currentTime));

      if (currentProgress >= 100) {
        setIsPlaying(false);
        setProgress(0);
      }
    };

    const setAudioDuration = () => {
      setDuration(formatTime(audio.duration));
    };

    audio.addEventListener("timeupdate", updateProgress);
    audio.addEventListener("loadedmetadata", setAudioDuration);

    setIsPlaying(true);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
      audio.removeEventListener("loadedmetadata", setAudioDuration);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      isPlaying ? audio.play() : audio.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = isMuted;
    }
  }, [isMuted]);

  const formatTime = (seconds: number): string => {
    const rounded = Math.floor(seconds);
    const minutes = Math.floor(rounded / 60);
    const remainingSeconds = rounded % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleProgressBarClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const progressBar = progressBarRef.current;
    const audio = audioRef.current;
    if (!progressBar || !audio) return;

    const clickPositionX = e.nativeEvent.offsetX;
    const progressBarWidth = progressBar.clientWidth;
    const clickPositionRatio = clickPositionX / progressBarWidth;
    const newTime = clickPositionRatio * audio.duration;
    audio.currentTime = newTime;
  };

  const handleMouseDown = (): void => {
    setIsDragging(true);
  };

  const handleMouseMove = (e: MouseEvent): void => {
    if (!isDragging) return;
    handleProgressBarClick(
      e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>
    );
  };

  const handleMouseUp = (): void => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);
  return (
    <div className="audio-player flex items-center gap-4 w-full bg-slate-200 shadow-md dark:bg-slate-700 px-3 py-4 rounded-2xl">
      {showImage && (
        <img
          src={image}
          alt={audioName}
          className="w-[100px] h-auto aspect-square object-cover rounded-xl"
        />
      )}

      <audio ref={audioRef} src={audioUrl} preload="metadata" />
      <div className="grid gap-4 w-full">
        {/* <span className="text-left dark:text-white">{audioName}</span> */}
        <div className="flex items-center gap-2 w-full">
          <p className="dark:text-white text-xs">{currentTime}</p>
          <div
            ref={progressBarRef}
            onClick={handleProgressBarClick}
            onMouseDown={handleMouseDown}
            className="rounded-xl w-full h-[5px] bg-slate-400"
          >
            <div
              className={`bg-primary rounded-xl h-[5px] `}
              style={{
                width: `${progress}%`,
                backgroundColor: "var(--primary)",
              }}
            ></div>
          </div>
          <p className="dark:text-white text-xs">{duration}</p>
        </div>
        <div className="flex items-center w-full">
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="text-slate-700 dark:text-white mx-auto"
          >
            {isPlaying ? <Pause /> : <Play />}
          </button>
          <button
            onClick={() => setIsMuted(!isMuted)}
            className="text-slate-700 dark:text-white"
          >
            {isMuted ? <VolumeX /> : <Volume2 />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;

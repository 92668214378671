import React, { FC, useState } from "react";
import MediaDrawer from "../../drawer/MediaDrawer";
import { Play, X } from "lucide-react";
import IframeDrawer from "../../drawer/IframeDrawer";

interface HtmlLinkCardProps {
  text: string;
  url: any;
}

const HtmlLinkCard: FC<HtmlLinkCardProps> = ({ text, url }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div onClick={handleCardClick} className="text-primary cursor-pointer">{text}</div>
      <IframeDrawer isOpen={showModal} onClose={closeModal}>
        <iframe
          src={url}
          width={100}
          height={100}
          className="w-full h-full min-h-[600px]"
        />
      </IframeDrawer>
    </>
  );
};

export default HtmlLinkCard;

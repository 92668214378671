export enum ActionTypes {
  USER_LOGIN_STATUS = "USER_LOGIN_STATUS",
  SET_USER_ID = "SET_USER_ID",
  SET_USER_EMAIL = "SET_USER_EMAIL",
  TOGGLE_DROPDOWN = "TOGGLE_DROPDOWN",
  SET_LANGUAGE = "SET_LANGUAGE",
  SET_LANGUAGE_JSON = "SET_LANGUAGE_JSON",
  SET_STT_LANGUAGE = "SET_STT_LANGUAGE",
  SET_LANGUAGE_DIRECTION = "SET_LANGUAGE_DIRECTION",
  SET_STELLA_AVATAR = "SET_STELLA_AVATAR",
  SET_SHOW_INPUT = "SET_SHOW_INPUT",
  UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY",
  SHOW_CHAT_LOADING = "SHOW_CHAT_LOADING",
  SET_SESSION_ID = "SET_SESSION_ID",
  RENDER_ANIMATION = "RENDER_ANIMATION",
  TOGGLE_AUDIO_MODE = "TOGGLE_AUDIO_MODE",
  SET_SUGGESTED_EXPERTS = "SET_SUGGESTED_EXPERTS",
  SET_USER_LOCATION = "SET_USER_LOCATION",
  SET_APP_THEME = "SET_APP_THEME",
  // Define other action types here
  SET_AUTH_TOKEN = "SET_AUTH_TOKEN",
  SET_MY_DOCUMENTS = "SET_MY_DOCUMENTS",
  SET_MY_DOCUMENTS_COUNT = "SET_MY_DOCUMENTS_COUNT",
}

import React, { useState, useEffect, useRef } from "react";
import { Play, Pause, Volume2, VolumeX } from "lucide-react";

interface AudioResponseProps {
  audioUrl: string;
  responseType: string;
}

const AudioResponse: React.FC<AudioResponseProps> = ({
  audioUrl,
  responseType,
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [duration, setDuration] = useState<string>("Loading...");
  const [currentTime, setCurrentTime] = useState<string>("0:00");
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  // Update progress and current time
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateProgress = () => {
      const currentProgress = (audio.currentTime / audio.duration) * 100;
      setProgress(currentProgress);
      setCurrentTime(formatTime(audio.currentTime));
    };

    audio.addEventListener("timeupdate", updateProgress);

    return () => {
      audio.removeEventListener("timeupdate", updateProgress);
    };
  }, []);

  // Play/Pause toggle
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      isPlaying ? audio.play() : audio.pause();
    }
  }, [isPlaying]);

  // Mute toggle
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.muted = isMuted;
    }
  }, [isMuted]);

  // Handle duration and metadata
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioDuration = () => {
      const durationValue = isFinite(audio.duration)
        ? formatTime(audio.duration)
        : "0:00";
      setDuration(durationValue);
    };

    audio.addEventListener("loadedmetadata", setAudioDuration);

    // Attempt to set duration in case metadata is already loaded
    setAudioDuration();

    return () => {
      audio.removeEventListener("loadedmetadata", setAudioDuration);
    };
  }, [audioUrl]);

  useEffect(() => {
    if (progress === 100) setIsPlaying(false);
  }, [progress]);

  // Formatting time
  const formatTime = (seconds: number): string => {
    const rounded = Math.floor(seconds);
    const minutes = Math.floor(rounded / 60);
    const remainingSeconds = rounded % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Handle progress bar click
  const handleProgressBarClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const progressBar = progressBarRef.current;
    const audio = audioRef.current;
    if (!progressBar || !audio) return;

    const clickPositionX = e.nativeEvent.offsetX;
    const progressBarWidth = progressBar.clientWidth;
    const clickPositionRatio = clickPositionX / progressBarWidth;
    const newTime = clickPositionRatio * audio.duration;
    audio.currentTime = newTime;
  };

  return (
    <div
      style={{ backgroundColor: responseType === "my" ? "" : "" }}
      className={`audio-player flex items-center gap-4 rounded-xl  ${
        responseType === "my"
          ? "bg-white/40 m-[-0.75rem] w-full  max-w-[276px] xs:max-w-[350px] md:max-w-[500px] min-w-[276px] px-3 py-4"
          : "w-max aspect-square p-3 sm:p-2"
      }`}
    >
      <audio ref={audioRef} src={audioUrl} preload="metadata" />
      {responseType === "server" ? (
        <button
          onClick={() => setIsPlaying(!isPlaying)}
          className="text-slate-800 dark:text-white mx-auto"
        >
          {isPlaying ? (
            <Pause className="w-4 h-4 sm:w-5 sm:h-5" />
          ) : (
            <Volume2 className="w-4 h-4 sm:w-5 sm:h-5" />
          )}
        </button>
      ) : (
        <div className="grid gap-4 w-full">
          <div className="flex items-center gap-2 w-full">
            <p className="dark:text-white text-xs">{currentTime}</p>
            <div
              ref={progressBarRef}
              onClick={handleProgressBarClick}
              className="rounded-xl w-full h-[5px] bg-slate-200"
            >
              <div
                className="bg-white rounded-xl h-[5px]"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            {/* <p className="dark:text-white text-xs">{duration}</p> */}
          </div>
          <div className="flex items-center w-full">
            <button
              onClick={() => setIsPlaying(!isPlaying)}
              className="text-slate-700 dark:text-white mx-auto"
            >
              {isPlaying ? <Pause /> : <Play />}
            </button>
            <button
              onClick={() => setIsMuted(!isMuted)}
              className="text-slate-700 dark:text-white"
            >
              {isMuted ? <VolumeX /> : <Volume2 />}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioResponse;

import React from "react";

const NotLoggedInItem = () => {
  const handleLogin = () => {
    window.location.href = `https://accounts.unitedwecare.com/?redirect=${window.location.href}`;
  };
  return (
    <div className="divide-y border py-5 rounded-3xl shadow-md max-w-xl mx-auto absolute bottom-5 w-[95%] left-[2.5%] sm:bottom-1/3 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2">
      <div className="grid px-4 py-2 text-center text-gray-700">
        <h5 className="text-lg font-medium tracking-tight dark:text-white">
          Please login to continue
        </h5>
        <p className="text-sm text-slate-600 dark:text-slate-300">
          You need to be logged in to view your documents
        </p>
        <button
          className="bg-primary-500 dark:bg-primary/80 px-5 py-2 rounded-full sm:rounded-lg text-sm text-white sm:mx-auto mt-5"
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default NotLoggedInItem;

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../../components/select/Select";
import { X } from "lucide-react";
import { CirclePlus } from "lucide-react";
import { Search } from "lucide-react";
import React from "react";

const DocumentSearch = ({
  selectedTag,
  handleValue,
  searchInput,
  handleInputChange,
}: any) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center gap-3">
      <div className="flex items-center gap-2 relative w-full md:max-w-sm ">
        <Search className="w-4 h-4 text-gray-600 absolute left-2" />
        <input
          placeholder="Filter documents..."
          value={searchInput}
          onChange={(e: any) => handleInputChange(e)}
          className="block w-full md:max-w-sm rounded-md border-0 py-1.5 pr-2 pl-8 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-slate-900 dark:text-slate-100"
        />
      </div>

      <div className="flex items-center gap-3">
        <Select value={selectedTag} onValueChange={handleValue}>
          <SelectTrigger className="w-max border-dashed dark:border-gray-100 ml-auto md:ml-0">
            <div className="flex items-center">
              <CirclePlus className="h-5 w-5 mr-2 text-muted-foreground" />
              Type
              {selectedTag && (
                <>
                  <div
                    data-orientation="vertical"
                    role="none"
                    className="shrink-0 bg-border w-[1px] mx-2 h-4"
                  ></div>
                  <div className="space-x-1 flex">
                    <div className="inline-flex items-center border py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary dark:bg-slate-100/30 text-secondary-foreground hover:bg-secondary/80 rounded-sm px-1 font-normal text-slate-500 dark:text-slate-100 capitalize">
                      <span>{selectedTag}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Doc Type</SelectLabel>
              <SelectItem value="prescription">Prescription</SelectItem>
              <SelectItem value="lab test">Lab Test</SelectItem>
              <SelectItem value="treatment plans">Treatment Plans</SelectItem>
              <SelectItem value="case history">Case History</SelectItem>
              <SelectItem value="health monitoring data">
                Health Monitoring Data
              </SelectItem>
              <SelectItem value="medication history">
                Medication History
              </SelectItem>
              <SelectItem value="medical image">Medical Image</SelectItem>
              <SelectItem value="surgical reports">Surgical Reports</SelectItem>
              <SelectItem value="others">Others</SelectItem>
              <SelectSeparator />
              <button
                className="py-1.5 pl-2 pr-8 text-sm text-center w-full"
                onClick={() => handleValue(null)}
              >
                Clear Filter
              </button>
            </SelectGroup>
          </SelectContent>
        </Select>
        {selectedTag && (
          <button
            className="inline-flex items-center gap-2 px-2 py-1.5 border rounded-md h-9 text-sm w-max"
            onClick={() => handleValue(null)}
          >
            <X className="w-4 h-4" />
            Clear Filters
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentSearch;
